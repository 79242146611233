@font-face {
    font-family: 'Bold';
    src: url('./BellotaText-Bold.ttf');
}
@font-face {
    font-family: 'Regular';
    src: url('./BellotaText-Regular.ttf');
}
@font-face {
    font-family: 'Light';
    src: url('./BellotaText-Light.ttf');
}