.Services-Details-Left-Oriented {
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Services-Details-Left {
    margin-right: 50px;
}
.Services-Details-Left-Name {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.Services-Details-Left-Name img{
    height: 40px;
    flex-shrink: 0;
}
.Services-Details-Left-Title {
    font-size: 30px;
    font-family: "Bold";
    color: #07031A;
    margin-left: 20px;
}
.Services-Details-Left-Paragraphs {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.Services-Details-Left-Paragraph {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 21px;
    font-family: 'Regular';
}
.Services-Details-Left-Demand {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Services-Details-Left-Button {
    font-family: 'Regular';
    margin-bottom: 10px;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    border: none;
    height: 40px;
    border-radius: 100px;
    background-color: #07031A;
    padding-right: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: hidden;
}
.Services-Details-Left-Button img {
    flex-shrink: 0;
    display: block;
    height: 40px;
    margin-right: 10px;
    transition: transform 0.8s ease;
}
.Services-Details-Left-Button:hover img {
    transform: rotate(180deg);
}
.Services-Details-Left-Button:hover{
    transform: translate(5%);
}
.Services-Details-Left-Full-Stack {
    margin-top: 10px;
    font-family: 'Regular';
    margin-bottom: 10px;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    border: none;
    height: 40px;
    border-radius: 100px;
    background-color: #0B8BC9;
    padding-right: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: hidden;
}
.Services-Details-Left-Full-Stack img {
    display: block;
    height: 40px;
    margin-right: 10px;
    transition: transform 0.8s ease;
    flex-shrink: 0;
}
.Services-Details-Left-Full-Stack:hover{
    transform: translate(5%);
}
/*Right side*/
.Services-Details-Right {
    margin-left: 50px;
    width: 900px;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Services-Details-Right-Name {
    display: flex;
    align-items: center;
}
.Services-Details-Right-Name img{
    height: 45px;
    flex-shrink: 0;
}
.Services-Details-Right-Title {
    font-size: 30px;
    font-family: "Bold";
    color: #000000;
}
.Services-Details-Right-Paragraphs {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: Left;
    gap: 5px;
}
.Services-Details-Right-Paragraph {
    margin-top: 25px;
    margin-left: 20px;
    margin-right: 20px;
}
.Services-Details-Right-Paragraph-Title {
    display: flex;
    align-items: center;
}
.Services-Details-Right-Paragraph-Title img {
    height: 22px;
    flex-shrink: 0;
}
.Services-Details-Right-Paragraph-Title p {
    font-family: "Regular";
    color: #000000;
    font-size: 21px;
    margin-left: 10px;
}
.Services-Details-Right-Subtitles {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    align-items: center;
}
.Services-Details-Right-Paragraph-Content {
    margin-top: 18px;
    font-family: 'Light';
    color: #000000;
    font-size: 21px;
}
.Services-Details-Right-File {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Services-Details-Right-File p{
    font-family: "Bold";
    font-size: 21px;
    text-align: center;
    color: #000000;
}
.Services-Details-Right-File a {
    text-decoration: none;
    margin-top: 5px;
    padding: 10px;
}
.Services-Details-Right-File button {
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 15px;
    cursor: pointer;
    background-color: black;
    color: #ffffff;
    font-family: "Bold";
    font-size: 20px;
    transition: all 0.3s ease;
}
.Services-Details-Right-File button:hover {
    transform: scale(1.05);
}
.Services-Details-Right-File img{
    width: 45px;
    margin-right: 15px;
    flex-shrink: 0;
}
@media only screen and (max-width: 1950px) {
    .Services-Details-Left-Oriented {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .Services-Details-Left-Name img{
        height: 35px;
    }
    .Services-Details-Left-Title {
        font-size: 25px;
    }
    .Services-Details-Left-Paragraphs {
        max-width: 650px;
    }
    .Services-Details-Left-Paragraph {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .Services-Details-Left-Button {
        font-size: 18px;
    }
    .Services-Details-Left-Full-Stack {
        font-size: 18px;
    }
    /*Right side*/
    .Services-Details-Right {

        padding: 20px 10px;
    }
    .Services-Details-Right-Name img{
        height: 35px;
    }
    .Services-Details-Right-Title {
        font-size: 25px;
    }
    .Services-Details-Right-Paragraph-Title img {
        height: 27px;
    }
    .Services-Details-Right-Paragraph-Title p {
        font-size: 18px;
    }
    .Services-Details-Right-Subtitle img {
        height: 20px;
    }
    .Services-Details-Right-Paragraph {
        margin-top: 25px;
    }
    .Services-Details-Right-Paragraph-Content {
        margin-top: 15px;
        font-size: 18px;
    }
    .Services-Details-Right-File {
        margin-top: 40px;
    }
    .Services-Details-Right-File p {
        font-size: 18px;
    }
    .Services-Details-Right-File a {
        margin-top: 15px;
    }
    .Services-Details-Right-File img {
        width: 40px;
    }
    .Services-Details-Right-File button {
        font-size: 18px;
    }
}
@media only screen and (max-width: 1800px) {
    .Services-Details-Left{
        margin-right: 15px;
    }
    .Services-Details-Right{
        margin-left: 15px;
    }
    .Services-Details-Right {
        width: 700px;
    }
}
@media only screen and (max-width: 1700px) {
    .Services-Details-Left-Paragraphs {
        max-width: 600px;
    }
}
@media only screen and (max-width: 1500px) {
    .Services-Details-Left-Oriented {
        width: 100%;
        flex-direction: column;
    }
    .Services-Details-Left {
        margin-right: 0px;
        padding: 0px;
    }
    .Services-Details-Left-Paragraphs {
        display: block;
        margin-bottom: 20px;
        max-width: 800px;
    }
    .Services-Details-Left-Demand {
        margin-bottom: 40px;
        flex-direction: row;
        justify-content: flex-start;
    }
    .Services-Details-Left-Button {
        margin: 0px;
        margin-right: 20px;
    }
    .Services-Details-Left-Name {
        justify-content: center;
    }
    .Services-Details-Left-Demand {
        margin-bottom: 40px;
        align-items: center;
    }
    .Services-Details-Right {
        margin-left: 0px;
        width: 800px;
        padding: 30px 20px;
    }
    .Services-Details-Right-Paragraphs {
        margin-top: 15px;
    }
    .Services-Details-Right-File {
        margin-top: 25px;
    }
    .Services-Details-Right-File a{
        margin-top: 10px;
    }
}
@media only screen and (max-width: 950px) {
    .Services-Details-Left-Paragraphs {
        width: 400px;
        margin-bottom: 20px;
    }
    .Services-Details-Right {
        width: 400px;
    }
    .Services-Details-Left-Demand {
        margin-bottom: 40px;
        flex-direction: column;
        justify-content: flex-start;
    }
    .Services-Details-Left-Button {
        margin-bottom: 10px;
        margin-right: 0px;
    }
    .Services-Details-Left {
        padding: 0px;
    }
    .Services-Details-Right-Paragraphs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        margin-top: 20px;
    }

}
@media only screen and (max-width: 600px) {
    .Services-Details-Left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Services-Details-Left-Demand {
        width: 100%;
        margin-bottom: 40px;
    }
    .Services-Details-Left-Name {
        margin-bottom: 20px;
    }
    .Services-Details-Left-Paragraphs {
        width: 85%;
    }
    .Services-Details-Right {
        width: 500px;
    }
}
@media only screen and (max-width: 550px) {
    .Services-Details-Right {
        margin-top: 25px;
        width: 100%;
        padding: 30px 20px;
    }
    .Services-Details-Right-Subtitles {
        width: 90%;
    }
    .Services-Details-Right-Subtitle p {
        font-size: 17px;
    }
}