.ServicesCard {
    width: 550px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 25px 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.ServicesCard img {
    height: 45px;
    margin-bottom: 10px;
    transition: all 0.8 ease;
    flex-shrink: 0;
}
.ServicesCard-Title {
    font-family: 'Bold';
    font-size: 30px;
    color: #000000;
    margin-bottom: 20px;
}
.ServicesCard-Subtitle{
    text-align: center;
    font-family: "Light";
    font-size: 23px;
    color: #000000;
    height: 60px;
}
.ServicesCard-Button {
    margin-top: 30px;
    width: 225px;
    font-size: 20px;
    font-family: 'Regular';
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50px;
    transition: transform 0.3s ease-in-out;
}
.ServicesCard-Button:hover {
    transform: scale(1.1);
}
.ServicesCard-Link {
    margin-top: 10px;
    font-family: 'Regular';
    font-size: 20px;
    color: #000000;
}
@media only screen and (max-width: 1950px) {
    .ServicesCard {
        width: 425px;
        padding: 30px 70px;
    }
    .ServicesCard img {
        height: 40px;
        margin-bottom: 10px;
    }
    .ServicesCard-Title {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .ServicesCard-Subtitle {
        font-size: 20px;
    }
    .ServicesCard-Button {
        margin-top: 20px;
        padding: 7px;
        width: 175px;
        font-size: 18px;
    }
    .ServicesCard-Link {
        margin-top: 5px;
        font-family: 'Regular';
        font-size: 18px;
    }
}
@media only screen and (max-width: 1600px){
    .ServicesCard {
        margin-top: 10px;
    }
}
@media only screen and (max-width: 550px) {
    .ServicesCard {
        width: 90%;
        height: auto;
        padding: 30px 80px;
    }
    .ServicesCard-Title {
        text-align: center;
        font-size: 25px;
        margin-bottom: 15px;
    }
    .ServicesCard-Button {
        margin-top: 20px;
        width: 200px;
        font-size: 20px;
        font-family: 'Regular';
    }
}
@media only screen and (max-width: 450px) {
    .ServicesCard {
        width: 100%;
        height: auto;
    }
    .ServicesCard {
        height: auto;
        padding: 20px 30px;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 450px) {
    .ServicesCard {
        padding: 30px 20px;
    }
}