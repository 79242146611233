.Portfolio {
    width: 100%;
    height: auto;
    padding-top: 110px;
    background: linear-gradient(to bottom, black 75%, white 25%);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Portfolio-Title {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Bold';
    font-size: 30px;
    margin-bottom: 50px;
}
.Portfolio-Subtitle {
    text-align: center;
    font-family: Light;
    font-size: 25px;
    color: #FFFFFF;
    margin-bottom: 75px;
}
.Portfolio-Cards {
    display: flex;
    width: 100%;
    justify-content: center;

}
@media only screen and (max-width: 1950px){
    .Portfolio {
        padding-top: 50px;
        background: linear-gradient(to bottom, black 70%, white 30%);
    }
    .Portfolio-Title {
        font-size: 25px;
        margin-top: 50px;
        margin-bottom: 35px;
    }
    .Portfolio-Subtitle {
        font-size: 20px;
        margin-bottom: 60px;
    }
}
@media only screen and (max-width: 1100px) {
    .Portfolio {
        background: linear-gradient(to bottom, black 80%, white 20%);
    }
    .Portfolio-Cards {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Portfolio {
        min-height: auto;
        padding-top: 50px;
        padding-bottom: 10px;
    }
    .Portfolio-Subtitle{
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 700px) {
    .Portfolio-Subtitle{
        width: 90%;
    }
}
