.Contact{
    padding-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 110px;
    background-color: #000000;
}
.Contact-Title {
    color: #ffffff;
    font-family: 'Bold';
    font-size: 30px;
    text-align: center;
    margin-bottom: 75px;
}
.Contact-Card {
    padding: 80px 80px;
    display: flex;
    background-color: #ffffff;
    box-shadow: 10px 10px 50px #5abaf6;
    justify-content: space-between;
    width: 1000px;
}
.Contact-Card-Left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Contact-Card-Left-Top {
    margin-bottom: 50px;
}
.Contact-Card-Title {
    font-family: 'Bold';
    font-size: 35px;
    color: #000000;
    margin-bottom: 10px;
}
.Contact-Card-Subtitle {
    font-family: 'Regular';
    font-size: 30px;
    color: #000000;
}
.Contact-Card-Info {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.Contact-Card-Info img {
    flex-shrink: 0;
    flex-grow: 0;
    width: 30px;
    margin-right: 20px;
}
.Contact-Card-Info a {
    font-family: 'Light';
    font-size: 23px;
    color: #000000;
}
.Contact-Card-Right img {
    height: 300px;
}
@media only screen and (max-width: 1950px) {
    .Contact{
        padding-top: 50px;
    }
    .Contact-Title {
        font-size: 25px;
        margin-bottom: 50px;
    }
    .Contact-Card {
        width: 850px;
    }
    .Contact-Card-Left-Top {
        margin-bottom: 50px;
    }
    .Contact-Card-Title {
        font-size: 30px;
    }
    .Contact-Card-Subtitle {
        font-size: 25px;
    }
    .Contact-Card-Info {
        margin-top: 25px;
    }
    .Contact-Card-Info img {
        width: 25px;
    }
    .Contact-Card-Info p {
        font-size: 20px;
    }
    .Contact-Card-Right img {
        height: 280x;
    }
}
@media only screen and (max-width: 850px) {
    .Contact-Title {
        margin-bottom: 50px;
    }
    .Contact-Card {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 80px 30px;
    }
    .Contact-Card-Left-Top {
        margin-bottom: 25px;
    }
    .Contact-Card-Left {
        margin-bottom: 50px;
    }
    .Contact-Card-Info p {
        width: 100%;
    }
}
@media only screen and (max-width: 750px) {
    .Contact-Card-Left-Top {
        text-align: center;
    }
    .Contact-Card-Info {
        display: flex;
        justify-content: center;
    }
}