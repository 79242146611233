@import './Fonts/Fonts.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    color: red;
  }

