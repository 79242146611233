/* Rozmazání pozadí */
.Demand-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.Demand-Overlay.blurred {
  backdrop-filter: blur(5px);
}

/* Načítací kolečko */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  z-index: 1000;
}

/* Animace pro načítací kolečko */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Tlačítko odeslání */
.Demand-Submit-Button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Demand-Submit-Button:hover {
  background-color: #217dbb;
}

.Demand-Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .Demand-Content {
    padding: 30px 20px;
    width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.172);
  }
  .Demand-Form {
    width: 700px;
  }
  .Demand-Close-Button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #000000;
  }
  .Demand-Title {
    font-size: 35px;
    font-family: 'Bold';
    margin-bottom: 75px;
  }
  .Demand-Duo {
    display: flex;
    justify-content: space-between;
  }
  .Demand-Duo .Demand-Group {
    width: 250px;
  }
  .Demand-Group {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Demand-Label {
    font-size: 20px;
    font-family: 'Regular';
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #000000 !important; 
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #000000 !important; /* Ujistí se, že text zůstane bílý i při focus */
  }
  .Demand-Select {
    -webkit-appearance: none; /* Zakáže výchozí styl WebKit prohlížečů */
    -moz-appearance: none;    /* Zakáže výchozí styl Firefoxu */
    appearance: none;         /* Zakáže výchozí styl moderních prohlížečů */
    padding-top: 15px;
    padding-bottom: 5px;
    font-family: 'Light';
    font-size: 20px;
    color: rgb(0, 0, 0);
    background-color: #FFFFFF;
    outline: none;
    border: none;
    border-bottom: 1px solid;
    width: 250px;
  }
  .Demand-Input {
    border-radius: 0px;
    padding-top: 15px;
    padding-bottom: 5px;
    font-family: 'Light';
    font-size: 20px;
    border: none;
    color: rgb(0, 0, 0);
    outline: none;
    border-bottom: 1px solid;
  }
  .Demand-Textarea {
    border-radius: 0px;
    border: none;
    color: rgb(0, 0, 0);
    font-family: 'Light';
    font-size: 20px;
    padding-top: 15px;
    resize: none;
    width: auto;
    height: 200px;
    outline: none;
    border-bottom: 1px solid;
  }
  .Demand-Submit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
  }
  .Demand-Submit-Button {
    background-color: #07031A;
    color: #FFFFFF;
    padding: 12px 30px;
    border: none;
    border-radius: 50px;
    font-family: 'Bold';
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  .Demand-Submit-Button:hover {
    transform: scale(1.05);
  }
  .error {
    font-family: "Light";
    font-size: 15px;
    color: rgb(175, 1, 1);
    padding-top: 5px;
  }
@media only screen and (max-height: 800px) {
  .Demand-Form {
    width: 700px;
    margin: 0 auto;
  }
  .Demand-Content {

    width: 1000px;
    height: 750px;
    border-radius: 0px;
    display: Block;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .Demand-Title {
    text-align: center;
  }
}
  @media only screen and (max-width: 1600px){
    .Demand-Content {
      width: 800px;
    }
    .Demand-Close-Button {
      font-size: 30px;
    }
    .Demand-Title {
      font-size: 25px;
    }
    .Demand-Group {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .Demand-Label {
      font-size: 19px;
      margin-bottom: 5px;
    }
    .Demand-Select {
      font-size: 17px;
    }
    .Demand-Input {
      font-size: 17px;
    }
    .Demand-Textarea {
      font-size: 17px;
      height: 180px;
    }
    .Demand-Submit-Button {
      padding: 10px 30px;
      font-size: 17px;
    }
  }
@media only screen and (max-width: 700px) {
  .Demand-Content {
    padding: 50px 10px;
    width: 100%;
  }
  .Demand-Title {
    font-size: 28px;
    margin-bottom: 35px;
  }
  .Demand-Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Demand-Select {
    width: 400px;
  }
  .Demand-Duo {
    flex-direction: column;
  }
  .Demand-Duo .Demand-Group {
    width: 400px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .Demand-Group {
    width: 400px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .Demand-Content {
    height: 100%;
    width: 100%;
  }
  .Demand-Title {
    text-align: center;
  }
  .Demand-Select {
    width: 100%;
  }
  .Demand-Duo {
    width: 100%;
    align-items: center;
  }
  .Demand-Duo .Demand-Group {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .Demand-Group {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
